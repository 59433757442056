.ReactModal__Overlay {
    overflow: auto;
    max-height: 100%;;
}
.alert-danger:empty {
    display: none !important;
}

.alert-success:empty {
    display: none !important;
}